const GoogleIcon = (props: { color?: string }) => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="28" height="28" rx="14" fill="white" />
      <path
        d="M24.4999 14.2303C24.4999 13.5506 24.4437 12.8671 24.3238 12.1984H14.7014V16.0492H20.2117C19.983 17.2911 19.2483 18.3898 18.1725 19.0879V21.5866H21.4599C23.3904 19.8449 24.4999 17.2728 24.4999 14.2303Z"
        fill="#4285F4"
      />
      <path
        d="M14.7015 24.0008C17.4529 24.0008 19.7732 23.1152 21.4637 21.5867L18.1763 19.088C17.2617 19.698 16.0809 20.0434 14.7052 20.0434C12.0438 20.0434 9.78724 18.2833 8.97757 15.917H5.58521V18.4928C7.317 21.8696 10.8443 24.0008 14.7015 24.0008Z"
        fill="#34A853"
      />
      <path
        d="M8.9738 15.9169C8.54648 14.6749 8.54648 13.3301 8.9738 12.0881V9.51233H5.58518C4.13827 12.338 4.13827 15.667 5.58518 18.4927L8.9738 15.9169Z"
        fill="#FBBC04"
      />
      <path
        d="M14.7015 7.95805C16.1559 7.936 17.5616 8.47247 18.6149 9.45722L21.5275 6.60218C19.6832 4.90459 17.2355 3.97128 14.7015 4.00067C10.8443 4.00067 7.317 6.13185 5.58521 9.51234L8.97383 12.0881C9.77975 9.71811 12.0401 7.95805 14.7015 7.95805Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleIcon;
