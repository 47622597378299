import {Button} from '@mui/material'
import adImage from '../../asset/images/introduce/ad.png'
import backgroundImage from '../../asset/images/introduce/background.png'
import deliverooImage from '../../asset/images/introduce/deliveroo.png'
import groupImage from '../../asset/images/introduce/group.png'
import linkImage from '../../asset/images/introduce/link.png'
import logoipsumImage from '../../asset/images/introduce/logoipsum.png'
import pieceImage from '../../asset/images/introduce/piece.png'
import CustomAccordion from '../../components/customAccordion'
import SolutionItem from '../../components/introduce/solutionItem'
import TourismItem from '../../components/introduce/tourismItem'
import {SectionTestimonial} from './Testimonial/Testimonial'
import FooterV2 from '../../components/footerV2'
import gonganIcon from '../../asset/images/introduce/gongan.png'

import gonglveIcon from '../../asset/images/introduce/gonglve.png'
import xinyuIcon from '../../asset/images/introduce/xinyu.png'
import yuangongIcon from '../../asset/images/introduce/yuangong.png'
import luxiangIcon from '../../asset/images/introduce/luxiang.png'
import shujutongbuIcon from '../../asset/images/introduce/shujutongbu.png'
import liuyanIcon from '../../asset/images/introduce/liuyan.png'
import quanqiuIcon from '../../asset/images/introduce/quanqiu.png'

import sousuoIcon from '../../asset/images/introduce/sousuo.png'
import shenhezhongxin_48Icon from '../../asset/images/introduce/shenhezhongxin_48.png'
import shujiIcon from '../../asset/images/introduce/shuji.png'
import gongwenbaoIcon from '../../asset/images/introduce/gongwenbao.png'

import jiesuoIcon from '../../asset/images/introduce/jiesuo.png'
import anquanfanghuIcon from '../../asset/images/introduce/anquanfanghu.png'
import cengjiIcon from '../../asset/images/introduce/cengji.png'

import RectangleIcon from '../../asset/images/introduce/Rectangle.png'
import Rectangle1Icon from '../../asset/images/introduce/Rectangle1.png'
import Rectangle2Icon from '../../asset/images/introduce/Rectangle2.png'
import Rectangle3Icon from '../../asset/images/introduce/Rectangle3.png'
import Rectangle4Icon from '../../asset/images/introduce/Rectangle4.png'
import Rectangle5Icon from '../../asset/images/introduce/Rectangle5.png'
import ImageIcon from '../../asset/images/introduce/Image.png'
import Image1Icon from '../../asset/images/introduce/Image1.png'
import Image2Icon from '../../asset/images/introduce/Image2.png'
import Image3Icon from '../../asset/images/introduce/Image3.png'
import Image4Icon from '../../asset/images/introduce/Image4.png'
import Image5Icon from '../../asset/images/introduce/Image5.png'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {useNavigate} from 'react-router-dom'
import {selectUserData, userActions} from '../../features/user/userSlice'
import {ROUTE} from '../../router/routes'

export interface DataType {
  image?: string
  title?: string
  description: string
  category?: string
  avatar?: string
  name?: string
  time?: string
}

const LIST_FEATURE: DataType[] = [
  {
    image: gonganIcon,
    title: '이벤트',
    description:
      '다양한 이벤트 기능을 통해 방문자의 재방문을 유도할 수 있습니다',
  },
  {
    image: gonglveIcon,
    title: '가격표',
    description:
      '매장별로 요금표 및 영업시간을 설정할 수 있는 유용한 가격표 기능을 제공하여, 이용자들에게 필요한 정보를 효과적으로 제공합니다',
  },
  {
    image: xinyuIcon,
    title: '블로그',
    description:
      '블로그 게시판을 활용하여 다양한 정보를 공유함으로써 사용자의 재방문률을 증가시킬 수 있습니다',
  },
  {
    image: yuangongIcon,
    title: '회원기능',
    description:
      '회원 관리가 용이하며, 등급별로 설정하여 각 회원에게 적절한 권한을 부여할 수 있습니다',
  },
  {
    image: luxiangIcon,
    title: '애드센스',
    description: '애드센스를 연결함으로써 플랫폼에서 수익을 창출할 수 있습니다',
  },
  {
    image: shujutongbuIcon,
    title: '관리자페이지',
    description:
      '관리자 페이지에서는 이용자 통계, 회원 관리, 매장 등록 등을 포함한 다양한 기능을 통해 유저 관리가 용이합니다',
  },
  {
    image: liuyanIcon,
    title: '메시징',
    description:
      '앱에서 다양한 회원간의 소통을 가능하게 하는 채팅 기능을 제공합니다',
  },
  {
    image: quanqiuIcon,
    title: '커뮤니티',
    description:
      '구인, 부동산, 커뮤니티 등 다양한 게시판을 선택하여 회원 간 소통을 지원하는 기능이 준비되어 있습니다',
  },
]

const LIST_SOLUTION: DataType[] = [
  {
    image: sousuoIcon,
    title: '구글,네이버 SEO 최적화',
    description:
      '이 플랫폼은 내장된 SEO 설정을 통해 다양한 포털 사이트에서 노출될 수 있으며, 별도의 개발자 없이도 최적의 혜택을 제공합니다',
  },
  {
    image: shenhezhongxin_48Icon,
    title: '엑셀로 한번에',
    description:
      '반복적인 업무와 커뮤니케이션을 엑셀을 사용해 한 번에 처리할 수 있습니다. 자동화 솔루션을 통해 효율성을 경험하세요',
  },
  {
    image: shujiIcon,
    title: '지속적인 업데이트',
    description:
      '저희 솔루션은 24시간 관리 및 개발을 거쳐, 매달 진행되는 지속적인 업데이트를 통해 새로운 기능이 추가되고 개선됩니다',
  },
  {
    image: gongwenbaoIcon,
    title: '개인과 기업 모두에게',
    description:
      '개인부터 기업에 이르기까지 다양한 환경에서 활용하기 용이한 솔루션입니다 플랫폼 운영에만 집중할 수 있습니다',
  },
]

const LIST_SECURITY: DataType[] = [
  {
    image: jiesuoIcon,
    title: '보안',
    description:
      '정기적인 업데이트를 통해 이러한 취약점을 해결하고, 최신 보안 패치를 적용함으로써 해커로부터 보호할 수 있습니다.',
  },
  {
    image: anquanfanghuIcon,
    title: '비밀번호 사용과 다단계 인증 (MFA)',
    description:
      '계정 보호를 위해 강력하고 예측 불가능한 비밀번호를 설정하고, 가능하다면 다단계 인증을 활성화하여 추가적인 보안 계층을 추가하는 것이 중요합니다. 이는 비밀번호가 유출되더라도 계정이 보호될 수 있도록 도와줍니다',
  },
  {
    image: cengjiIcon,
    title: '데이터 관리',
    description:
      '데이터 보안은 데이터를 무단 액세스, 변경, 손실 또는 파괴로부터 보호하는 모든 조치를 포함합니다. 접근 제어등 데이터 관리를 용이하게 할 수 있습니다',
  },
]

const LIST_FAQ: DataType[] = [
  {
    title: 'GM4솔루션은 어떤 기능들이 있나요?',
    description:
      'GM4 솔루션은 웹과 앱을 사용자가 쉽게 접근할 수 있도록 다양한 기능을 제공하는 노코드 플랫폼입니다. 사용자 경험을 극대화하고 개발자 없이도 고급 게시판들을 선택해 손쉽게 구현할 수 있도록 돕습니다.',
  },
  {
    title: '노코드로 Web 사이트와 Google Play와 App Store 출기가 가능한가요?',
    description:
      'GM4 솔루션을 통해 사용자는 노코드 방식으로 손쉽게 모바일 앱을 출시할 수 있습니다. 뿐만 아니라, 배포 과정 역시 간소화되어 빠르게 시장에 출시할 수 있습니다. 이러한 접근 방식은 개발에 대한 에너지 소비없이 오로지 운영에만 집중할 수 있고, 비용을 크게 절감하면서도 높은 품질의 앱을 제공할 수 있도록 도와줍니다.',
  },
  {
    title: '서버 비용은 얼마이고 어떻게 결제하나요?',
    description:
      '서버 비용은 트래픽과 업로드 이미지 및 파일 양에 따라 서버비용이 상이할 수 있으며, 평균 5만원~20만원대로 이용 가능하십니다. 자신의 페이지 내에서 서버비용을 편하게 결제가 가능하십니다.',
  },
  {
    title: 'GM4 솔루션 사용하려면, 제가 준비할 것은 무엇이 있나요?',
    description:
      'GM4 솔루션을 이용하여 웹이나 앱을 제작하려면, 구매자가 준비해야 할 것은 최소한의 기획 자료와 아이디어뿐입니다. 이 솔루션은 노코드 기반으로 설계되어 있어, 전문적인 개발 지식이 없어도 쉽게 사용할 수 있습니다. 또한, GM4 솔루션은 다양한 템플릿과 도구를 제공해 프로젝트를 신속하게 시작하고, 구글 플레이나 앱스토어에 손쉽게 배포할 수 있도록 도와줍니다. 이렇게 준비된 환경 덕분에, 구매자는 복잡한 기술적 문제에 신경 쓰지 않고 본인의 아이디어를 현실로 구현하는 데 집중할 수 있어, 웹과 앱 제작이 매우 편리하게 느껴질 것입니다.',
  },
  {
    title: '구글 애드센스 연결은 어떻게 하나요?',
    description:
      '구글 애드센스를 연결하려면, 먼저 구글 애드센스 계정에 가입하고 승인을 받아야 합니다. 이후 관리자 페이지에 접속하여 제공된 애드센스 광고 코드를 복사한 뒤, 쉽게 접근할 수 있는 코드 삽입 기능을 사용 하면 됩니다. GM4 솔루션의 관리자 페이지는 이러한 코드 삽입 과정을 매우 직관적으로 지원하므로, 복잡한 설정 없이도 광고를 빠르게 웹사이트나 앱에 통합할 수 있습니다. 이를 통해 사용자는 광고 수익을 손쉽게 창출할 수 있습니다.',
  },
]

const LIST_FIELD: DataType[] = [
  {
    image: RectangleIcon,
    category: '020플랫폼',
    description:
      '이 플랫폼은 특히 매장 관리, 요금 설정, 영업시간 조정 등을 포함한 다양한 사용자 맞춤 설정을 제공하며, 누구나 쉽게 접근하고 활용할 수 있는 간편한 인터페이스를 갖추고 있습니다',
    avatar: ImageIcon,
    name: 'Park Ji Sung',
    time: 'August 22, 2023',
  },
  {
    image: Rectangle1Icon,
    category: '여행 플랫폼',
    description:
      '전 세계 여행자들에게 맞춤형 여행 경험을 제공하는 혁신적인 솔루션으로, 사용자 친화적인 기능과 포괄적인 서비스를 갖추고 있어 눈길을 끕니다. 이 플랫폼은 예약 시스템 덕분에 매우 만족합니다',
    avatar: Image1Icon,
    name: 'Choi Si won',
    time: 'August 13, 2023',
  },
  {
    image: Rectangle2Icon,
    category: '뷰티 구인 플랫폼',
    description:
      '업계 종사자와 고용주 간의 연결을 용이하게 하는 혁신적인 플랫폼입니다. 특정 업계의 고유 요구를 충족시키기 위해 설계해 운영하고 있습니다 지속적인 업데이트에 감사를 전합니다',
    avatar: Image2Icon,
    name: 'Han So Hee',
    time: 'August 12, 2023',
  },
  {
    image: Rectangle3Icon,
    category: '블로그 플랫폼',
    description:
      '블로그를 운영하여 애드센스를 연동하고 수익을 얻는 경험은 매우 긍정적이었습니다. 이 플랫폼은 사용자가 쉽게 콘텐츠를 생성하고 관리하는데 매우 효과적입니다',
    avatar: Image3Icon,
    name: 'Lee Hyori',
    time: 'August 10, 2023',
  },
  {
    image: Rectangle4Icon,
    category: '커뮤니티 플랫폼',
    description:
      '커뮤니티를 운영하고 있고 사용자 간의 상호작용과 정보 공유를 극대화하는 데 중점을 둔 플랫폼입니다. GM4솔루션을 만나게 되어 매우 기분이 좋습니다',
    avatar: Image4Icon,
    name: 'Moon Jae In',
    time: 'August 22, 2023',
  },
  {
    image: Rectangle5Icon,
    category: '중고 플랫폼',
    description:
      '이 플랫폼은 간편한 검색 기능, 사용자 간의 신뢰를 구축할 수 있는 평가 시스템을 갖추고 있어 추천해요',
    avatar: Image5Icon,
    name: 'Shin Hye Sun',
    time: 'August 24, 2023',
  },
]

const Introduce = () => {
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(selectUserData)
  const navigator = useNavigate()
  return (
    <div className='xl:max-w-[1440px] xl:m-auto'>
      <div
        className='xl:flex xl:justify-center xl:gap-8 xl:items-center xl:pt-8'
        style={{backgroundImage: `url(${backgroundImage})`}}
      >
        <div className='py-8 pt-0 text-center px-3 flex flex-col gap-3 xl:w-[35%] xl:text-start'>
          <p className='text-sm font-bold'>SOLUTION</p>
          <h1 className='text-[22px] font-bold xl:text-[44px]'>
            이제는 코딩없이 웹사이트와 어플리케이션을 만들어보세요
          </h1>
          <p className='font-medium text-[#52525B]'>
            개발자 없이 웹과 앱을 한 번에 만들 수 있는 플랫폼 GM4 솔루션{' '}
          </p>

          <div className='px-8 flex gap-4 xl:justify-start xl:px-0'>
            <Button
              variant='contained'
              className='w-2/5 !font-bold xl:max-w-[132px]'
              onClick={() => {
                if (!userProfile._id) dispatch(userActions.setLogin(true))
                else {
                  navigator(ROUTE.SITELISTANDEXPIREDLIST)
                }
              }}
            >
              시작하기
            </Button>
            <Button
              variant='outlined'
              className='w-3/5 !font-bold xl:max-w-[187px]'
              onClick={() => {
                if (!userProfile._id) dispatch(userActions.setLogin(true))
                else {
                  navigator(ROUTE.CUSTOMERCENTER)
                }
              }}
            >
              영업팀과 대화하기
            </Button>
          </div>
        </div>
        <div className='xl:w-[50%]'>
          <img src={adImage} alt='ad.png' />
        </div>
      </div>
      <SectionTestimonial />
      <div className='bg-[#F2F8FF] px-4 py-6 flex flex-col gap-6 xl:px-[100px]'>
        <div className='w-full text-center xl:text-start flex items-center flex-col xl:flex-row xl:justify-end xl:relative'>
          <img
            className='w-[119px] h-[119px] xl:w-[413px] xl:h-[413px] xl:absolute xl:left-[10%] top-[-190px]'
            src={pieceImage}
            alt='piece.png'
          />
          <div className='flex flex-col gao-[10px] xl:w-[50%]'>
            <p className='text-[22px] font-bold xl:text-[40px]'>
              솔루션 하나에 수많은 기능
            </p>
            <p className='text-[#52525B] font-medium'>
              다양한 플랫폼과 필요한 맞춤 기능들이 한가득 준비되어 있어요
            </p>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row gap-3 xl:gap-5 xl:flex-wrap'>
          {LIST_FEATURE.map((item) => (
            <SolutionItem data={item} key={item.image} />
          ))}
        </div>
        <hr className='border-white border-[1px]' />

        <div className='w-full text-center xl:text-start flex items-center flex-col xl:flex-row-reverse xl:justify-around xl:items-center'>
          <img
            className='w-[229px] h-[173px] xl:w-[542px] xl:h-[407px]'
            src={linkImage}
            alt='link.png'
          />
          <div className='flex flex-col gap-[10px]'>
            <p className='text-[22px] font-bold xl:text-[40px]'>
              GM4 솔루션의 특별함
            </p>
            <p className='text-[#52525B] font-medium'>
              이 플랫폼은 자동 SEO 설정이 내장되어 있어, 별도의 SEO 최적화
              작업이 필요하지 않습니다션
            </p>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row gap-3 xl:gap-5 xl:flex-wrap'>
          {LIST_SOLUTION.map((item) => (
            <SolutionItem data={item} key={item.image} />
          ))}
        </div>
        <hr className='border-white border-[1px]' />
        <div className='w-full xl:text-start text-center flex items-center xl:items-start flex-col xl:flex-row xl:justify-around'>
          <img
            className='w-[181px] h-[137px] xl:w-[417px] xl:h-[316px]'
            src={groupImage}
            alt='group.png'
          />
          <div className='flex flex-col gao-[10px] '>
            <p className='text-[22px] font-bold xl:text-[40px]'>
              최적화된 보안
            </p>
            <p className='text-[#52525B] font-medium'>
              우수한 보안 체계는 경쟁력을 갖추어 시장에서 안전한 플랫폼 운영을
              가능하게 지원합니다
            </p>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row gap-3 xl:gap-5 xl:flex-wrap'>
          {LIST_SECURITY.map((item) => (
            <SolutionItem
              data={item}
              key={item.image}
              className='xl:w-[calc(33.33%-13.3px)]'
            />
          ))}
        </div>
      </div>

      <div className='py-8 xl:flex xl:justify-center xl:gap-14'>
        <img
          src={deliverooImage}
          alt='deliveroo.png'
          className='xl:max-w-[510px]'
        />
        <div className='px-4 flex flex-col gap-5 xl:max-w-[448px]'>
          <p className='text-lg xl:text-2xl'>
            “이 솔루션을 사용하면서 무엇보다 인상 깊었던 점은 개발자 없이도
            노코드 방식으로 플랫폼을 완전히 운영할 수 있다는 것입니다. 우리 팀은
            복잡한 코딩 작업 없이도 필요한 모든 설정과 관리를 손쉽게 할 수
            있었습니다”
          </p>
          <img
            className='w-[107px] h-[23px] xl:w-[113px] xl:h-[27px]'
            src={logoipsumImage}
            alt='newlife.png'
          />
          <p>
            <b>Will Sprunt</b>, Newlife CIO
          </p>
        </div>
      </div>

      <div className='py-6 px-4 flex flex-col gap-x-2 gap-y-6 xl:px-[100px]'>
        <div className='flex flex-col gap-3 text-center'>
          <p className='text-[28px] font-bold xl:text-[32px]'>
            다양한 분야에서 유용하게 활용 가능한 솔루션
          </p>
          <p className='text-sm xl:text-base'>
            기술적인 배경 지식이 없는 사용자도 쉽게 접근할 수 있으면서, 다양한
            업종과 산업에서 효과적으로 활용할 수 있어요
          </p>
        </div>
        <div className='flex flex-wrap gap-x-2 gap-y-6 xl:gap-5'>
          {LIST_FIELD.map((item) => (
            <TourismItem
              data={item}
              key={item.image}
              className='xl:w-[calc(33.33%-13.3px)] xl:p-4'
            />
          ))}
        </div>
      </div>
      <div className='p-4 xl:py-[60px]'>
        <div className='rounded-xl p-3 xl:p-6 xl:max-w-[1020px] xl:m-auto shadow-customSm xl:shadow-customXl'>
          <h4 className='text-[28px] font-bold text-center xl:text-[32px] xl:mb-6'>
            자주 묻는 질문
          </h4>
          {LIST_FAQ.map((item) => (
            <CustomAccordion data={item} key={item.title} />
          ))}
        </div>
      </div>
      <div className='xl:h-[370px] xl:items-center xl:justify-center p-8 bg-[#3872FF] text-white text-center text-[22px] font-bold flex flex-col gap-6 xl:mb-16'>
        <h3 className='xl:w-[456px] xl:text-[28px]'>
          노코드 플랫폼을 활용한 디지털 노마드 생활 시작 가이드
        </h3>
        <div className='flex gap-4'>
          <Button
            className='!bg-white !font-bold w-[107px] h-12'
            onClick={() => {
              if (!userProfile._id) dispatch(userActions.setLogin(true))
              else {
                navigator(ROUTE.SITELISTANDEXPIREDLIST)
              }
            }}
          >
            시작하기
          </Button>
          <Button
            variant='outlined'
            className='!flex-1 h-12 !border-white !border-[1px] !text-white'
            onClick={() => {
              if (!userProfile._id) dispatch(userActions.setLogin(true))
              else {
                navigator(ROUTE.CUSTOMERCENTER)
              }
            }}
          >
            영업팀과 대화하기
          </Button>
        </div>
      </div>
      <FooterV2 />
    </div>
  )
}

export default Introduce
