import React from 'react'

const SendIcon = (props: {color?: string}) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.8474 18.8939L25.136 12.0281C27.1353 6.03021 28.1349 3.03129 26.5519 1.44826C24.9689 -0.134761 21.97 0.864879 15.9721 2.86416L9.10633 5.15275C4.26548 6.76637 1.84506 7.57318 1.15725 8.7563C0.502923 9.88182 0.502922 11.2719 1.15725 12.3974C1.84506 13.5806 4.26548 14.3874 9.10632 16.001C9.70625 16.201 10.3812 16.0582 10.8304 15.6131L18.1713 8.34026C18.5843 7.9311 19.2507 7.93421 19.6599 8.34719C20.0691 8.76018 20.066 9.42666 19.653 9.83582L12.4307 16.9912C11.9355 17.4818 11.7788 18.2326 11.9992 18.8938C13.6128 23.7347 14.4196 26.1551 15.6027 26.8429C16.7282 27.4972 18.1183 27.4972 19.2439 26.8429C20.427 26.1551 21.2338 23.7347 22.8474 18.8939Z'
        fill={props.color ? props.color : '#777E91'}
      />
    </svg>
  )
}

export default SendIcon
