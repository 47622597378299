import React from 'react'
import {DataType} from '../../pages/ introduce'

interface TourismItemProps {
  className?: string
  data?: DataType
}

const TourismItem = ({className, data}: TourismItemProps) => {
  return (
    <div
      className={` rounded-lg border-[1px] w-[calc(50%-4px)] p-[1px] flex flex-col gap-2 h-fit ${className}`}
    >
      <img src={data?.image} alt={data?.image} className='w-full rounded-md' />
      <div className='px-1 flex flex-col gap-1'>
        <span className='p-1 xl:p-2 bg-[#F6F8FF] rounded-md text-xs xl:text-sm text-[#3872FF] w-fit'>
          {data?.category}
        </span>
        <p className='font-bold text-sm h-[140px] xl:h-fit xl:text-2xl'>
          {data?.description}
        </p>
        <br />
        <div className='xl:flex xl:gap-5 xl:items-center'>
          <div className='flex items-center gap-1 xl:gap-4'>
            <img
              className='w-6 h-6 rounded-full xl:w-[36px] xl:h-[36px]'
              src={data?.avatar}
              alt={data?.avatar}
            />
            <span className='text-[#97989F] font-bold text-xs xl:text-base'>
              {data?.name}
            </span>
          </div>
          <div className='text-[#97989F] text-[10px] xl:text-base'>
            {data?.time}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TourismItem
