import IntroductionLogo1 from '../../asset/images/introduction/first.png'
import IntroductionLogo2 from '../../asset/images/introduction/second.png'
import IntroductionLogo3 from '../../asset/images/introduction/third.png'
import FooterV2 from '../../components/footerV2'
import {FeedbackCarousel} from '../../components/homepage/FeedbackCarousel/FeedbackCarousel'
import {SectionMasthead} from '../../components/homepage/Masthead/SectionMasthead'
import {SectionBuyPlan} from '../../components/homepage/SectionBuyPlan/SectionBuyPlan'
import {SectionFeedback} from '../../components/homepage/SectionFeedback/SectionFeedback'
import {SectionRegisterPlan} from '../../components/homepage/SectionRegisterPlan/SectionRegisterPlan'
import {SectionSolution} from '../../components/homepage/SectionSolution/SectionSolution'
import {SectionTestimonial} from './Testimonial/Testimonial'
//Mobile: width < 768px
//Tablet: 768px < width < 1024px
//Desktop: width >=1024px

const Home = () => {
  return (
    <div className='xl:max-w-[1440px] xl:m-auto lg:max-w-[768px] max-w-[100vw] m-auto'>
      <SectionMasthead />
      <SectionTestimonial />
      <section
        data-name='Section-Introduction'
        className='xl:w-full xl:flex xl:justify-center xl:bg-[#FBF9F5] p-4 xl:p-0'
      >
        <div className='xl:flex xl:flex-row xl:flex-nowrap xl:gap-[30px] xl:items-center xl:px-[140px] xl:py-[66px] xl:justify-between  flex flex-col-reverse'>
          <div className='xl:max-w-[600px] xl:py-10 xl:gap-8 xl:flex xl:flex-col flex flex-col gap-2'>
            <p className='xl:text-[48px] font-bold text-[22px] text-center xl:text-start'>
              솔루션 하나에 수많은 기능
            </p>
            <p className='xl:text-xl text-center xl:text-start text-base'>
              복잡한 코딩 지식이 없어도 몇 번의 클릭만으로 전문가 수준의 디지털
              프레젠스를 완성할 수 있습니다
            </p>
          </div>
          <img
            loading='lazy'
            src={IntroductionLogo1}
            alt={'logo'}
            className='xl:w-[578px] xl:h-[282px]'
          />
        </div>
      </section>
      <section
        data-name='Section-Introduction'
        className='xl:w-full xl:flex xl:justify-center xl:bg-[#FBF9F5] p-4 xl:p-0'
      >
        <div className='xl:flex xl:flex-nowrap xl:gap-[30px] xl:items-center xl:px-[140px] xl:py-[66px] xl:justify-between xl:flex-row-reverse  flex flex-col-reverse'>
          <div className='xl:max-w-[600px] xl:py-10 xl:gap-8 xl:flex xl:flex-col flex flex-col gap-2'>
            <p className='xl:text-[48px] font-bold text-[22px] text-center xl:text-start'>
              사용자 친화적인 인터페이스
            </p>
            <p className='xl:text-xl text-center xl:text-start text-base'>
              다양한 업종과 필요에 맞춘 맞춤형 템플릿을 제공하여, 개별
              비즈니스의 특성을 살린 맞춤형 디자인을 가능하게 합니다. 또한,
              효율적인 데이터 관리와 세련된 디자인으로 사용자 경험을 극대화할 수
              있도록 지원하며, 모든 디바이스에서 완벽하게 작동하는 반응형
              디자인을 제공합니다.
            </p>
          </div>
          <img
            loading='lazy'
            src={IntroductionLogo2}
            alt={'logo'}
            className='xl:w-[513px] xl:h-[541px]'
          />
        </div>
      </section>
      <section
        data-name='Section-Introduction'
        className='xl:w-full xl:flex xl:justify-center xl:bg-[#FBF9F5] p-4 xl:p-0'
      >
        <div className='xl:flex xl:flex-row xl:flex-nowrap xl:gap-[30px] xl:items-center xl:px-[140px] xl:py-[66px] xl:justify-between  flex flex-col-reverse'>
          <div className='xl:max-w-[600px] xl:py-10 xl:gap-8 xl:flex xl:flex-col flex flex-col gap-2 xl:mt-0 mt-4 '>
            <p className='xl:text-[48px] font-bold text-[28px] text-start xl:text-start '>
              GM4 솔루션을 통해 귀하의 비즈니스를 다음 단계로 이끌어 보세요
            </p>
            <p className='xl:text-xl text-center xl:text-start text-base'>
              효과적이고 직관적인 디지털 솔루션으로 경쟁력을 갖추고 시장에서
              돋보일 수 있습니다.
            </p>
          </div>
          <img
            loading='lazy'
            src={IntroductionLogo3}
            alt={'logo'}
            className='xl:w-[578px] xl:h-[282px]'
          />
        </div>
      </section>

      <SectionSolution />
      <SectionRegisterPlan />
      <div className='py-6 px-4 xl:py-0 xl:px-0'>
        <SectionFeedback />
        <FeedbackCarousel />
      </div>
      <SectionBuyPlan />
      <FooterV2 />
    </div>
  )
}

export default Home
