import React from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store} from './app/store'
import reportWebVitals from './reportWebVitals'
import './index.css'
import Router from './router'
import BaseAdminLayout from './layouts/admin'
import {createTheme, ThemeProvider} from '@mui/material'
import Loading from './components/loading'
import CustomizedSnackbars from './components/snackbar'

const container = document.getElementById('root')!
const root = createRoot(container)

const theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans KR'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#3872FF',
          height: '48px',
        },
        outlinedPrimary: {
          border: '1px solid #3872FF',
          color: '#3872FF',
          height: '48px',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440, // NOTE: container Fluid
      xl: 1536,
    },
  },
})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <BaseAdminLayout>
            <Loading />
            <CustomizedSnackbars />
            <Router />
          </BaseAdminLayout>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
