import {Typography} from '@mui/material'
import Stack from '@mui/material/Stack'
// import EditIcon from "../../../asset/icons/edit.svg";
import CheckBasic from '../../../../asset/icons/check-basic.svg'
import CheckPremium from '../../../../asset/icons/check-premium.svg'

import {StyledButton, StyledContainer} from './RegisterPlanCard.styled'

export type TPlan = 'basic' | 'premium'

export const RegisterPlanCard: React.FC<{variant: TPlan}> = ({variant}) => {
  const PlanOption: Record<
    TPlan,
    {
      eyebrow: string
      title: string
      price: string
      options: string[]
    }
  > = {
    basic: {
      eyebrow: 'WEB 초기 세팅 비용',
      title: 'Basic Plan',
      price: '3,000,000원',
      options: [
        '서버 비용 별도',
        'WEB',
        'Admin Page',
        '게시판 설정 기능 제공',
        '검색엔진 최적화',
      ],
    },
    premium: {
      eyebrow: 'O2O플랫폼 솔루션',
      title: 'Premium Plan',
      price: '5,500,000원',
      options: [
        '트래픽 무제한 - AWS 서버',
        '사이트 & APP',
        '관리자 페이지',
        '디자이너 지원',
        '컨설팅',
      ],
    },
  }

  const planSelected = PlanOption[variant]
  const isPremium = variant === 'premium'

  return (
    <StyledContainer isPremium={isPremium} width='310px' minWidth={'310px'}>
      <Typography
        fontWeight='bold'
        fontSize='16px'
        color={isPremium ? 'white' : '#3872FF'}
        mb='12px'
      >
        {planSelected.eyebrow}
      </Typography>
      <Typography color={isPremium ? 'white' : '#3F3F46'} mb='16px'>
        {planSelected.title}
      </Typography>
      <Typography
        fontWeight='bold'
        mb='22px'
        color={isPremium ? 'white' : '#18181B'}
      >
        {planSelected.price}
      </Typography>

      <Stack flexDirection='column' gap='12px' mb='80px'>
        {planSelected.options.map((label) => (
          <Stack
            key={label}
            flexDirection='row'
            flexWrap='nowrap'
            gap='8px'
            alignItems='center'
          >
            <img
              width='24px'
              height='24px'
              src={variant === 'basic' ? CheckBasic : CheckPremium}
            />
            <Typography color={isPremium ? 'white' : '#3F3F46'}>
              {label}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <StyledButton isPremium={isPremium}>Buy plan</StyledButton>
    </StyledContainer>
  )
}
