// import Swiper core and required modules
import {Autoplay, FreeMode} from 'swiper/modules'

import {Swiper as SwiperContainer, SwiperSlide} from 'swiper/react'
import FeedbackAvatar1 from '../../../asset/images/feedback-1.png'
import FeedbackAvatar2 from '../../../asset/images/feedback-2.png'
import FeedbackAvatar3 from '../../../asset/images/feedback-3.png'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import {
  FeedbackCard,
  FeedbackCardProps,
} from '../Cards/FeedbackCard/FeedbackCard'

const mockFeedback: FeedbackCardProps[] = [
  {
    feedback:
      '이 플랫폼 덕분에 복잡한 코딩 작업 없이도 전문적인 웹사이트와 앱을 손쉽게 구축할 수 있었습니다. 이제 저는 기술적인 문제에 시간을 낭비하지 않고, 비즈니스 성장에만 집중할 수 있습니다.',
    avatar: FeedbackAvatar1,
    name: '방용석',
    title: '팝스피아 대표',
  },
  {
    feedback:
      '회사에서 새로운 프로젝트로 음악 관련 커뮤니티 앱을 제작하게 되었습니다. 많은 곳을 찾아보았지만, 저희가 원하는 가격과 기능 그리고 지속적인 업데이트로 개발자 없이 비즈니스에만 몰두할 수 있어 매우 만족합니다.',
    avatar: FeedbackAvatar2,
    name: '김화선',
    title: '음악 비즈니스',
  },
  {
    feedback: '저희를 위해 개발된 모바일 앱 DigitX는 우리의 기대를 뛰어넘었습니다. 사용자 친화적인 인터페이스와 원활한 기능은 사용자들로부터 극찬을 받았습니다.',
    avatar: FeedbackAvatar3,
    name: '마이클 존슨',
    title: 'GlobalTech Solutions의 마케팅 매니저',
  },
  {
    feedback: 'DigitX와의 작업은 즐거웠습니다. 그들의 웹 디자인 팀은 우리 브랜드의 본질을 완벽하게 담은 멋진 웹사이트를 만들어주었습니다. 고객들로부터의 피드백은 매우 긍정적이었습니다.',
    avatar: FeedbackAvatar3,
    name: '김화선',
    title: '음악 비즈니스',
  }
]

export const FeedbackCarousel = () => {
  return (
    <div className='xl:pb-8 xl:w-full p-4 xl:max-w-[1440px]'>
      <SwiperContainer
        slidesPerView={4}
        spaceBetween={16}
        freeMode
        grabCursor
        speed={5000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        modules={[FreeMode, Autoplay]}
        className='mySwiper'
      >
        {[...mockFeedback, ...mockFeedback]?.map((props, idx) => (
          <SwiperSlide
            className='xl:!w-[420px] !w-[230px] !p-0'
            key={`feedback-item-${props?.name}-${idx}`}
          >
            <FeedbackCard {...props} />
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </div>
  )
}
