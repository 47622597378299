import {
  StyledStartButton,
  StyledTalkToSaleButton,
} from './SectionBuyPlan.styled'

import backgroundImage from '../../../asset/images/home/background1.png'

export const SectionBuyPlan = () => {
  return (
    <section
      data-name='Section-SectionBuyPlan'
      className='xl:w-full xl:flex xl:justify-center xl:relative xl:mt-[128px] xl:mb-[64px] p-4'
    >
      <div
        className='flex flex-col xl:gap-6 gap-3 xl:py-8 items-center justify-center bg-[#2563EB] rounded-2xl xl:p-[60px] py-6 px-4'
        style={{background: `url("${backgroundImage}")`}}
      >
        <p className='xl:text-[36px] font-bold text-[#FAFAFA] text-[22px] text-center'>
          Solution으로 이룰 수 있는 모든 가능성을 확인해보세요!
        </p>
        <p className='xl:text-base text-[#FAFAFA] text-sm text-center font-light'>
          GM4 Solution을 통하여 여러분이 원하는 비즈니스를 이루어 보세요.
        </p>
        <div className='xl:mt-6 gap-4 flex flex-nowrap items-center'>
          <StyledStartButton>Start now</StyledStartButton>
          <StyledTalkToSaleButton>Talk to Sales</StyledTalkToSaleButton>
        </div>
      </div>
    </section>
  )
}
