import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Button} from '@mui/material'
import GoogleIcon from '../../../asset/icons/GoogleIcon'
import mastheadBackground from '../../../asset/images/MastheadV2.png'
import adImage from '../../../asset/images/home/ad.png'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {selectUserData, userActions} from '../../../features/user/userSlice'
import {useNavigate} from 'react-router-dom'
import {ROUTE} from '../../../router/routes'

export const SectionMasthead = () => {
  const dispatch = useAppDispatch()
  const userProfile = useAppSelector(selectUserData)
  const navigator = useNavigate()

  return (
    <section
      data-name='Section-mastHeader'
      style={{
        backgroundImage: `url(${mastheadBackground})`,
      }}
      className='xl:w-full xl:flex xl:justify-center flex justify-center'
    >
      <div className='xl:mt-10 xl:max-w-[1090px]  xl:flex xl:flex-col xl:gap-8 xl:justify-center py-6 pt-0 flex flex-col items-center gap-4'>
        <div className='xl:max-w-none max-w-[283px] xl:flex xl:justify-center xl:gap-6 xl:flex-col flex flex-col items-center gap-3'>
          <div className='xl:flex xl:justify-center xl:gap-3 xl:flex-col flex flex-col gap-3'>
            <p className='text-center xl:text-[44px] font-bold text-[22px]'>
              코딩없이 누구나 손쉽게 웹사이트와 애플리케이션을 구성할 수 있어요
            </p>
            <p className='text-center xl:text-xl text-xl'>
              개발자 없이 웹과 앱을 한 번에 쉽고 빠르게 구성할 수 있는 혁신적인
              플랫폼을 제공합니다
            </p>
          </div>
          <div className='flex gap-4 flex-nowrap justify-center'>
            <Button
              variant='outlined'
              endIcon={<ArrowForwardIcon />}
              className='xl:!w-[130px] !rounded-full !w-[102px] !text-sm'
              onClick={() => {
                if (!userProfile._id) dispatch(userActions.setLogin(true))
                else {
                  navigator(ROUTE.SITELISTANDEXPIREDLIST)
                }
              }}
            >
              시작
            </Button>
            <Button
              variant='contained'
              startIcon={<GoogleIcon />}
              className='xl:!w-[193px] !rounded-full !flex-1 xl:!text-sm !text-[12.5px]'
              onClick={() => {
                if (!userProfile._id) dispatch(userActions.setLogin(true))
                else {
                  navigator(ROUTE.SITELISTANDEXPIREDLIST)
                }
              }}
            >
              Google로 로그인
            </Button>
          </div>
        </div>
        <img
          src={adImage}
          alt='adImage'
          className='xl:w-full w-[calc(100vw)]'
        />
      </div>
    </section>
  )
}
