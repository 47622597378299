import React from 'react'

const Apple = (props: { color?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3776 5.12714C10.564 4.64875 10.8901 4.23755 11.3135 3.94701C11.7368 3.65646 12.2378 3.50003 12.7512 3.49805C12.8862 3.49805 13.0157 3.55168 13.1112 3.64716C13.2066 3.74263 13.2603 3.87212 13.2603 4.00714C13.2603 4.14216 13.2066 4.27165 13.1112 4.36712C13.0157 4.46259 12.8862 4.51623 12.7512 4.51623C12.4426 4.51719 12.1414 4.61124 11.8871 4.78608C11.6328 4.96092 11.4371 5.20842 11.3257 5.49623C11.2884 5.59228 11.2228 5.67472 11.1375 5.73267C11.0523 5.79062 10.9515 5.82134 10.8485 5.82077C10.7878 5.81947 10.7277 5.80873 10.6703 5.78896C10.6074 5.76501 10.5499 5.72883 10.501 5.68251C10.4522 5.6362 10.4131 5.58066 10.3859 5.51912C10.3586 5.45757 10.3439 5.39124 10.3425 5.32396C10.341 5.25668 10.353 5.18978 10.3776 5.12714ZM16.1876 13.158C15.7807 12.9537 15.4361 12.644 15.1897 12.2612C14.9433 11.8783 14.804 11.4364 14.7865 10.9815C14.7689 10.5266 14.8737 10.0752 15.0899 9.67458C15.3061 9.27392 15.6258 8.93858 16.0157 8.7035C16.0763 8.66511 16.1282 8.61467 16.1684 8.55531C16.2085 8.49596 16.236 8.42897 16.2492 8.35852C16.2623 8.28807 16.2608 8.21567 16.2447 8.14583C16.2286 8.07599 16.1983 8.01021 16.1557 7.95259C15.464 7.07126 14.4574 6.49258 13.3477 6.33829C12.238 6.184 11.1117 6.46613 10.2057 7.12532C9.56016 6.65573 8.79727 6.37388 8.00145 6.31094C7.20564 6.248 6.40792 6.40643 5.69656 6.7687C4.98519 7.13097 4.3879 7.68296 3.97076 8.3636C3.55363 9.04425 3.3329 9.82702 3.33301 10.6253C3.34832 12.3037 3.85494 13.9408 4.79028 15.3344C5.70664 16.6899 6.87119 17.498 7.91483 17.498H12.4966C13.9285 17.498 15.5448 15.9771 16.4357 13.8071C16.4859 13.6878 16.4881 13.5537 16.4419 13.4328C16.3956 13.3119 16.3045 13.2135 16.1876 13.158Z"
        fill={props.color ? props.color : '#343941'} />
    </svg>
  )
}

export default Apple
