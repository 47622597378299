import {Button} from '@mui/material'

import {RegisterPlanCard} from '../Cards/RegisterPlanCard/RegisterPlanCard'

export const SectionRegisterPlan = () => {
  return (
    <section
      data-name='Section-RegisterPlan'
      className='xl:w-full xl:flex xl:justify-center xl:relative'
      id='Section-RegisterPlan'
    >
      <div className='xl:max-w-[857px] flex flex-col xl:gap-6 xl:py-11 px-4 xl:px-0 gap-4'>
        {/* render title */}
        <div className='flex flex-col justify-center xl:gap-[22px] gap-3 items-center'>
          <div className='xl:flex xl:flex-col xl:justify-center xl:gap-3 xl:items-center'>
            <p className='xl:w-fit text-center xl:text-[44px] font-bold text-[22px]'>
              초기 설정 비용
            </p>
            <p className='text-[#52525B] text-center xl:text-xl text-base'>
              시간과 비용을 절감하면서도 효율적이고 매력적인 웹사이트와 앱을
              구축할 수 있는 기회를 얻게 됩니다
            </p>
          </div>
          <Button
            variant='contained'
            sx={{
              background: '#3872FF',
              borderRadius: '100px',
              width: '150px',
              color: 'white',
              py: '12px',
              px: '24px',
            }}
          >
            첫 개설 비용
          </Button>
        </div>

        {/* render Cards */}
        <div className='flex flex-nowrap w-full xl:justify-center xl:gap-6 xl:mb-6 xl:items-center overflow-auto gap-3'>
          <RegisterPlanCard variant={'basic'} />
          <RegisterPlanCard variant={'premium'} />
        </div>
        {/* <RegisterInfo /> */}
      </div>
    </section>
  )
}
