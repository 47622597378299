import EditIcon from '@mui/icons-material/Edit'
import {Button} from '@mui/material'
import SolutionBackground from '../../../asset/images/background-solution.png'
import CalBackground from '../../../asset/images/solution/backgroung-caculator.svg'
import CNIcon from '../../../asset/images/solution/cn.svg'
import KORIcon from '../../../asset/images/solution/kor.svg'
import THBIcon from '../../../asset/images/solution/thb.svg'
import VieIcon from '../../../asset/images/solution/vie.svg'

export const SectionSolution = () => {
  const caculate: {value: number; label: string}[] = [
    {
      value: 563,
      label: '누적 사이트 이용 수',
    },
    {
      value: 3,
      label: '일 평균 개설 수',
    },
    {
      value: 47,
      label: '전국 파트너',
    },
  ]

  return (
    <section
      data-name='Section-Solution'
      style={{
        backgroundImage: `url(${SolutionBackground})`,
      }}
      className='xl:w-full xl:flex xl:justify-center xl:relative my-6 xl:my-0'
    >
      <div className='xl:max-w-[1030px] xl:flex xl:flex-col xl:gap-8 xl:pt-[95px] xl:pb-[26px]'>
        <div className='flex mb-6 gap-4'>
          <img
            src={KORIcon}
            alt='KORIcon'
            className='xl:absolute xl:top-[75px] xl:left-[166px] w-[71px] h-[32px] xl:!w-[87px] xl:!h-[44px] flex-1'
          />
          <img
            src={THBIcon}
            alt='THBIcon'
            className='xl:absolute xl:top-[62px] xl:right-[172px] w-[71px] h-[32px] xl:w-[87px] xl:h-[44px] flex-1'
          />
          <img
            src={VieIcon}
            className='xl:absolute xl:top-[216px] xl:left-[166px] w-[71px] h-[32px] xl:w-[87px] xl:h-[44px] flex-1'
            alt='VieIcon'
          />
          <img
            src={CNIcon}
            className='xl:absolute xl:top-[225px] xl:right-[152px] w-[71px] h-[32px] xl:w-[87px] xl:h-[44px] flex-1'
            alt='CNIcon'
          />
        </div>
        <div className='xl:flex xl:flex-col xl:justify-center xl:gap-6 xl:items-center flex flex-col gap-4 justify-center items-center'>
          <div className='xl:flex xl:flex-col xl:justify-center xl:gap-3 xl:items-center flex flex-col justify-center items-center gap-3'>
            <p className='xl:w-fit xl:text-center xl:text-[44px] xl:font-bold text-center text-[22px] font-bold max-w-[343px] xl:max-w-none'>
              크고 작은 기업이 GM4 Solution과 함께 합니다
            </p>
            <p className='xl:w-fit text-[#52525B] text-center xl:text-xl text-base font-bold max-w-[343px] xl:max-w-[542px]'>
              기업이나 개인에게 GM4 솔루션은 최적의 선택입니다. 지금 GM4
              솔루션을 통해 귀하의 비즈니스를 다음 단계로 이끌어 보세요.
              효과적이고 직관적인 디지털 솔루션으로 경쟁력을 갖추고 시장에서
              돋보일 수 있습니다
            </p>
          </div>
          <Button
            variant='contained'
            startIcon={<EditIcon />}
            className='!bg-[#3872FF] !rounded-full xl:!w-[150px] xl:!text-white xl:!py-3 xl:!px-6'
          >
            시작하기
          </Button>
        </div>
        <div
          className='xl:mt-[34px] xl:rounded-3xl bg-[#F5F5F5] xl:py-[60px] xl:px-[90px] xl:justify-between flex xl:flex-nowrap mx-4 xl:mx-0 rounded-3xl flex-wrap justify-center py-4 mt-[32px]'
          style={{backgroundImage: `url(${CalBackground})`}}
        >
          {caculate?.map((c) => (
            <div className='flex flex-col gap-2 items-center w-[50%] text-center' key={c.value}>
              <p className='font-bold text-[#3872FF] xl:text-[44px] text-2xl'>
                {c.value}
              </p>
              <p className='text-[#52525B] xl:text-2xl'>{c.label}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
