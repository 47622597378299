import FunnyBoxImage from '../../../asset/images/testimonials/funnybot.png'
import HPChatImage from '../../../asset/images/testimonials/hp-chat.png'
import MoneyImage from '../../../asset/images/testimonials/money.png'
import NewlifeImage from '../../../asset/images/testimonials/newlife.png'
import SeafullImage from '../../../asset/images/testimonials/seafull.png'


const TestimonialListing = [
  HPChatImage,
  FunnyBoxImage,
  MoneyImage,
  NewlifeImage,
  SeafullImage,
]

export const SectionTestimonial = () => {
  return (
    <div className='flex w-full overflow-auto py-3 xl:justify-center xl:h-[106px] xl:border-y-[1px] xl:flex xl:items-center xl:mt-4'>
      {TestimonialListing?.map((src) => (
        <img
          className='h-[30px] xl:w-[196px] xl:h-[56px]'
          key={src}
          src={src}
          alt={'TestimonialListing-image'}
        ></img>
      ))}
    </div>
  )
}
