import React from 'react'

const CHPlay = (props: { color?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0694 9.13823L5.58912 3.13253C5.43467 3.04463 5.25979 2.99892 5.08208 3.00002C4.90436 3.00112 4.73007 3.04897 4.57671 3.13878C4.42645 3.22554 4.30171 3.35035 4.21503 3.50065C4.12836 3.65096 4.08282 3.82145 4.08301 3.99495V16.0063C4.08466 16.2704 4.19073 16.5232 4.37807 16.7093C4.56542 16.8955 4.8188 17 5.08292 17C5.25998 16.9994 5.43405 16.9543 5.58912 16.8688L16.0694 10.8631C16.2247 10.7788 16.3543 10.6542 16.4447 10.5025C16.535 10.3507 16.5827 10.1773 16.5827 10.0006C16.5827 9.824 16.535 9.65064 16.4447 9.49884C16.3543 9.34705 16.2247 9.22246 16.0694 9.13823ZM11.0949 10.7068L12.276 11.888L6.76401 15.0377L11.0949 10.7068ZM6.76401 4.95737L12.276 8.11332L11.0949 9.29446L6.76401 4.95737ZM13.1697 11.3693L11.801 10.0006L13.1697 8.63202L15.5694 10.0006L13.1697 11.3693Z"
        fill={props.color ? props.color : '#343941'} />
    </svg>
  )
}

export default CHPlay
