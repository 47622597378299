import {DataType} from '../../pages/ introduce'

interface SolutionItemProps {
  className?: string
  data?: DataType
}

const SolutionItem = ({className, data}: SolutionItemProps) => {
  return (
    <div
      className={`p-3 flex flex-col gap-2 bg-white rounded-lg xl:w-[calc(25%-15px)] ${className}`}
    >
      <img className='w-10 h-10' src={data?.image} alt='' />
      <p className='font-bold'>{data?.title}</p>
      <p className='text-sm font-medium'>{data?.description}</p>
    </div>
  )
}

export default SolutionItem
