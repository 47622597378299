import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import {useState} from 'react'
import plusCycleIcon from '../../asset/images/introduce/plusCircle.png'
import uPlusCycleIcon from '../../asset/images/introduce/uPlusCircle.png'
import {DataType} from '../../pages/ introduce'

interface CustomAccordion {
  data?: DataType
}

const CustomAccordion = ({data}: CustomAccordion) => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = () => {
    setExpanded(!expanded)
  }
  return (
    <Accordion onChange={handleChange}>
      <AccordionSummary
        expandIcon={
          expanded ? (
            <img className='w-6' src={uPlusCycleIcon} alt='collapse icon' />
          ) : (
            <img className='w-6' src={plusCycleIcon} alt='expand icon' />
          )
        }
        aria-controls='panel1-content'
        id='panel1-header'
      >
        <p className='font-bold text-base xl:text-xl'>{data?.title}</p>
      </AccordionSummary>
      <AccordionDetails>
        <p className='text-[#3F3F46] text-sm font-medium xl:text-base'>
          {data?.description}
        </p>
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion
