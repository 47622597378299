import {Button} from '@mui/material'
import {styled} from '@mui/material/styles'

export const StyledStartButton = styled(Button)(({theme}) => ({
  borderRadius: '60px',
  color: '#1E40AF',
  fontSize: '13px',
  fontWeight: 'bold',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingInline: '10px',
  border: 'solid white 1px',
  background: 'linear-gradient(to right, #FFFFFF 0%, #C6D8FF 100%)',
  [theme.breakpoints.down('lg')]: {},
}))

export const StyledTalkToSaleButton = styled(Button)(({theme}) => ({
  color: '#FAFAFA',
  borderRadius: '60px',
  fontSize: '13px',
  fontWeight: 'bold',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingInline: '10px',
  border: 'solid #FAFAFA 1px',
  background:
    'linear-gradient(to bottom,rgba(255, 255, 255, 0.15) 0%,rgba(255, 255, 255, 0.25) 100%)',
  [theme.breakpoints.down('lg')]: {},
}))
