import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import registerPremiumBg from "../../../../asset/images/register-card-premium-bg.png";

export const StyledContainer = styled(Stack)<{ isPremium: boolean }>(
  ({ theme, isPremium }) => ({
    padding: "24px",
    borderRadius: "20px",
    backgroundColor: isPremium ? "#3B82F6" : "#F5F5F5",
    backgroundImage: isPremium ? `url(${registerPremiumBg})` : "",
    [theme.breakpoints.down("lg")]: {},
  })
);

export const StyledButton = styled(Button)<{ isPremium: boolean }>(
  ({ theme, isPremium }) => ({
    padding: "24px",
    height: "40px",
    borderRadius: "100px",
    color: isPremium ? "#3872FF" : "white",
    background: isPremium
      ? "linear-gradient(to right, #FFFFFF 0%, #C6D8FF 100%)"
      : "linear-gradient(to right, #2563EB 0%, #0044DA 100%)",

    [theme.breakpoints.down("lg")]: {},
  })
);
