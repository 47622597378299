export const SectionFeedback = () => {
  return (
    <section
      data-name='Section-SectionFeedback'
      className='xl:w-full xl:flex xl:justify-center xl:relative'
    >
      <div className='xl:flex xl:flex-col xl:gap-6 xl:py-8'>
        {/* render title */}
        <div className='flex flex-col justify-center xl:gap-3 xl:w-fit items-center gap-4'>
          <p className='xl:w-fit text-center xl:text-[44px] font-bold text-[22px]'>
            비즈니스만 할 수 있는
            <br /> 확실한 도구입니다
          </p>
          <p className='text-[#52525B] text-center xl:text-xl text-base font-medium'>
            지속적인 지원과 업데이트를 통해,
            <br className='xl:hidden' /> 비즈니스가 변화하는 시장 조건에
            <br className='xl:hidden' />
            신속하게 적응하고 성장할 수 있도록
            <br className='xl:hidden' /> 도와드립니다
          </p>
        </div>
      </div>
    </section>
  )
}
