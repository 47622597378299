import {Avatar} from '@mui/material'
import FeedBackBottom from '../../../../asset/icons/feedback-bottom.svg'
import FeedbackImg from '../../../../asset/images/feedback.png'

export type FeedbackCardProps = {
  feedback: string
  avatar: string
  name: string
  title: string
}

export const FeedbackCard: React.FC<FeedbackCardProps> = ({
  feedback,
  avatar,
  name,
  title,
}) => {
  return (
    <div className='flex flex-col w-full gap-[35px]'>
      <div className='relative xl:p-[30px] p-4 rounded-[14px] border-[1px] border-solid border-[#3872FF] flex flex-col xl:gap-6 gap-3'>
        <img className='xl:w-11 xl:h-11 w-8 h-8' src={FeedbackImg} alt='' />
        <p className='text-base'>{feedback}</p>
        <img
          width={32}
          height={19}
          src={FeedBackBottom}
          className='absolute bottom-[-16px] left-[14px]'
          alt=''
        />
      </div>
      <div className='flex flex-wrap gap-[10px]'>
        <Avatar sx={{width: '50px', height: '50px'}} src={avatar}>
          {name.at(0)}
        </Avatar>
        <div>
          <p className='xl:text-lg'>{name}</p>
          <p className='text-sm text-[#808080]'>{title}</p>
        </div>
      </div>
    </div>
  )
}
